import React from "react";
import { InlineWidget } from "react-calendly";
import "./index.css";
import { useMediaQuery } from "react-responsive";

const DemoCalendar = () => {
  //   <!-- Calendly inline widget begin -->
  // <div class="calendly-inline-widget" data-url="https://calendly.com/emanuel-kumar-alphametricx/teams-meeting" style="min-width:320px;height:700px;"></div>
  // <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
  // <!-- Calendly inline widget end -->
  const isMobile = useMediaQuery({
    query: "(max-width: 1000px)",
  });

  return (
    <div className="inline-widget">
      <InlineWidget
        styles={{
          width: isMobile ? "500px" : "1000px",
          height: isMobile ? "500px" : "700px",
          overflow: "hidden",
          scrollbarWidth: "none",
          marginTop: isMobile ? "7%" : "2%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        url="https://calendly.com/emanuel-kumar-alphametricx/new-meeting"
      />
    </div>
  );
};

export default DemoCalendar;
