import React, { useEffect } from "react";
import Header from "../header";
import Footer from "../footer";
import "./index.css";
import OurAIIcon from "../../../assets/img/our-ai-icon.png";
import OurAITimeIcon from "../../../assets/img/our-ai-time-icon.png";
import {
  OurSolutionsMockData,
  prSolutionFlexWrapData,
} from "../../../constants/mock";
import CircleArrow from "../../../assets/icons/CircleArrow";
import MediaTable from "../../our-solutions-table";
import PRBgImage from "../../../assets/img/pr-bg-img.png";
import AlphaMetricxAI from "../alphametricx-ai";

const OurSolutions = () => {
  const handleNavigate = () => {};

  const playAnimations = () => {
    const titleSplit = document.querySelectorAll(".our-animated-title");
    titleSplit.forEach((element, i) => {
      element.setAttribute(
        "style",
        `animation-delay:${0.1}s;-webkit-animation-delay:${0.1}s;`
      );
    });
    console.log(titleSplit);
  };

  useEffect(() => {
    window.addEventListener("load", playAnimations);
    return () => window.removeEventListener("load", playAnimations);
  }, []);

  return (
    <div className="Our-Solution-Wrap">
      <div className="Our-Solution-Container our-animated-title">
        <Header />

        <div className="our-solution-content">
          <div className="our-header-wrap">
            <div className="our-pr-text">
              AI Powered <img src={OurAIIcon} alt="" className="our-icon" /> PR
              & <br />
              Communications Insights{" "}
              <img src={OurAITimeIcon} alt="" className="our-icon" />
            </div>
          </div>
          <div className="our-pr-description">
            AlphaMetricx empowers media analysts and PR Managers to move beyond
            basic media tracking and complement reports with actionable
            insights.
            <br />
            You can use AlphaMetricx’s real-time data and AI driven metrics on
            sentiment, reach, etc. to optimize media relations, boost media
            performance, and manage crisis better. AlphaMetricx helps you plan,
            execute, and optimize PR campaigns while showcasing the real value
            of PR efforts.
          </div>
        </div>
        {/* Cards code */}
        <div className="our-card-section">
          <div className="our-border-section"></div>
          <div className="our-cards">
            {OurSolutionsMockData?.map((mediaItem, id) => (
              <div className="our-card-wrap" key={id}>
                <div className="our-card-img">
                  <img src={mediaItem?.imageURL} alt="img" />
                </div>
                <div className="our-card-text-wrp">
                  <div className="our-card-heading">{mediaItem?.title}</div>
                  <div className="our-card-description">
                    {mediaItem?.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Bottom Portion */}
      <div className="advanced-container">
        <div className="request-wrap">
          <div className="request-text">Request Demo</div>
          {/* <div> */}
          <CircleArrow />
          {/* </div> */}
        </div>

        <div className="adv-text-wrap">
          <div className="adv-bold-header">
            Advanced Metrics & Dashboards To Drive <br /> Your Strategies &
            Quantify Impact
          </div>
          <div className="adv-descp">
            AlphaMetricx for Media Analysts, PR Managers, & Campaign Strategists
          </div>
        </div>

        {/* Table */}
        <div className="media-wrap">
          <MediaTable />
        </div>

        {/* PR Image */}
        <div className="pr-image-wrap">
          <img src={PRBgImage} alt="" className="pr-image" />
        </div>

        <div className="adv-text-wrap">
          <div className="adv-bold-header">
            AlphaMetricx for PR Campaign <br />
            Strategists & Executives
            <div className="adv-descp">
              AlphaMetricx for Media Analysts, PR Managers, & Campaign
              Strategists
            </div>
          </div>
        </div>
        {/* Solutions */}
        <div className="pr-solutions-container">
          <AlphaMetricxAI data={prSolutionFlexWrapData} />
        </div>
      </div>

      <div className="Section5">
        <div className="Row-80">
          <div className="Col-50">
            <div className="S5-title">
              <h1>
                What our <br></br>
                early adopters <br></br>
                think?
              </h1>
            </div>
          </div>
          <div className="Col-50">
            <div className="Testimonial-slider-con">
              <div className="Testimonial-text">
                “The suite of products aims to solve the problems faced by
                modern PR, communications, and marketing professionals”
              </div>
              <div className="Testimonial-subtext">
                Austin Diesel, <br />
                ABC
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OurSolutions;
