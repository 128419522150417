import articleSample from "../assets/img/article-1.png";
import webinarArticle from "../assets/img/article-2.png";
import productionArticle from "../assets/img/article-3.png";
import featureImageSentiment from "../assets/img/Feature-Sentiment.png";
import featureImageCompetitor from "../assets/img/Feature-Competitor.png";
import featureImageDashboard from "../assets/img/Feature-Dashboard.png";
import AutomateImageNewsletters from "../assets/img/automate-newsletter.png";
import CorporateImageReputation from "../assets/img/corporate.png";
import PersonImageOne from "../assets/img/Alpha-AI-One.png";
import PersonImageTwo from "../assets/img/Alpha-AI-Two.png";
import PersonImageThree from "../assets/img/Alpha-AI-Three.png";
import PersonImageFour from "../assets/img/Alpha-AI-Four.png";
import PersonImageFive from "../assets/img/Alpha-AI-Five.png";
import PersonImageSix from "../assets/img/Alpha-AI-Six.png";
import MediaIntelligence from "../assets/img/our-media-intell.png";
import MediaMeasurement from "../assets/img/our-media-meaurement.png";
import MediaPRMetrics from "../assets/img/our-pr-metrics.png";

// export const countries = [
//     {label:'India', value:'India'},
//     {label:'Usa', value:'Usa'},
//     {label:'Canada', value:'Canada'},
//     {label:'Australia', value:'Australia'},
//     {label:'Uk', value:'uk'},
//     {label:'Ireland', value:'Australia'},
//     {label:'Australia', value:'Australia'},
//     {label:'Singapore', value:'Singapore'},
//     {label:'Germany', value:'germany'},
//     {label:'Spain', value:'spain'},
//     {label:'China', value:'china'},
// ];
export const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];
// export const countries = [
//     { name: 'INDIA', value: 'india' },
//     { name: 'USA', value: 'usa' },
//     { name: 'CANADA', value: 'canada' },
//     { name: 'AUSTRALIA', value: 'australia' },
//     { name: 'UK', value: 'uk' },
//     { name: 'IRELAND', value: 'ireland' },
//     { name: 'Rusia', value: 'russia' },
//     { name: 'Singapore', value: 'singapore' },
//     { name: 'Germany', value: 'germany' },
//     { name: 'Germany', value: 'germany' },
//     { name: 'Spain', value: 'spain' },
//     { name: 'China', value: 'hcina' },
// ];

export const companySize = [
  "1 - 50",
  "51 - 100",
  "101 - 500",
  "500 - 1000",
  "1000+",
];

// export const solutions = [
//     {name:'Media Intelligence', value:'Media Intelligence'},
//     {name:'Social Listening & Analytics', value:'Social Listening & Analytics'},
//     {name:'Consumer Intelligence', value:'Consumer Intelligence'},
//     {name:'Media Relations', value:'Media Relations'},
//     {name:'Influencer Marketing', value:'Influencer Marketing'},
//     {name:'Sales Intelligence', value:'Sales Intelligence'},
//     {name:'Data & API Integration', value:'Data & API Integration'},
// ];

export const solutions = [
  { label: "Media Intelligence", value: "Media Intelligence" },
  {
    label: "Social Listening & Analytics",
    value: "Social Listening & Analytics",
  },
  { label: "Consumer Intelligence", value: "Consumer Intelligence" },
  { label: "Media Relations", value: "Media Relations" },
  { label: "Influencer Marketing", value: "Influencer Marketing" },
  { label: "Sales Intelligence", value: "Sales Intelligence" },
  { label: "Data & API Integration", value: "Data & API Integration" },
];

export const articleData = [
  {
    id: 1,
    authorname: " Carl Heyendral",
    date: "Jan 2024",
    category: "Product Update",
    articleCategory: "Press Release",
    articleTitle:
      "InfoVision Inc. Launches AlphaMetricx for AI-Powered Media Intelligence",
    articleHeading:
      "InfoVision Inc. Launches AlphaMetricx for AI-Powered Media Intelligence",
    // articleDes:
    articleDes:
      "Fight Off the Risk of Reputation Damage. Protect your brand name by proactively monitoring and engaging",
    imageUrl: articleSample,
    bgColor: "#E56C23",
  },
  {
    id: 2,
    authorname: "NEAL E. BOUDETTE",
    date: "Jan 2024",
    category: "News & Articles",
    articleCategory: "Changing Role of Corporate Communications",
    articleTitle:
      "Media Listening Tools and the Changing Role of Corporate Communications",
    articleHeading:
      "Media Listening Tools and the Changing Role of Corporate Communications",
    articleDes:
      "Fight Off the Risk of Reputation Damage. Protect your brand name by proactively monitoring and engaging",
    imageUrl: webinarArticle,
    bgColor: "#BE3B93",
  },
  {
    id: 3,
    authorname: "BOBBY BRACROS",
    date: "Jan 2024",
    category: "News & Articles",
    articleCategory: "Challenges of Media Monitoring Teams",
    articleTitle:
      "Challenges of Media Monitoring and Addressing Them through AlphaMetricx",
    articleHeading:
      "Challenges of Media Monitoring and Addressing Them through AlphaMetricx",
    articleDes:
      "Fight Off the Risk of Reputation Damage. Protect your brand name by proactively monitoring and engaging",
    imageUrl: productionArticle,
    bgColor: "#BE3B93",
  },
];

export const featureData = [
  {
    id: 1,
    title: "Sentiment Analysis",
    image: featureImageSentiment,
    // description: 'Gain valuable insights into the emotions associated with your brand’s media presence across channels.',
    description:
      "With AlphaMetricx’s AI you can stay in tune with the public sentiment and the emotional tone of your target audience. Make data driven strategies to shape public perception.",
  },

  {
    id: 2,
    title: "Competitor Tracking",
    image: featureImageCompetitor,
    // description: 'Stay a step ahead of your competition by keeping a watchful eye on their activities and its impact.'
    description:
      "Know your competition like the back of your hand. Use AlphaMetricx’s AI to monitor, benchmark, and outpace your competitors through data-driven strategies.",
  },

  {
    id: 3,
    title: "Custom Dashboards",
    image: featureImageDashboard,
    // description: 'Build your own dashboards to tell your brand’s story and accelerate executive decision-making.'
    description:
      "Create, customize, and manage your dashboards using our AI feature. Visualize data and metrics of your choice and personalize insights.",
  },

  {
    id: 4,
    title: "Automate Newsletters",
    image: AutomateImageNewsletters,
    // description: 'Set up newsletters around key topics and automate their delivery to relevant stakeholder teams.'
    description:
      "Use our AI capabilities to create and distribute engaging newsletters to your leadership team. Personalize newsletters for different stakeholder groups based on their areas of interest.",
  },

  {
    id: 5,
    title: "Corporate Reputation",
    image: CorporateImageReputation,
    // description: 'Monitor media stories that can impact your corporate reputation and mitigate risks in timely manner.'
    description:
      "Use our AI to take control of your corporate narrative and manage your reputation proactively. Capitalize on positive opportunities and mitigate risks in timely manner.",
  },
];

export const PricingData = [
  { id: 1, des: "Volumes of 1+ million" },
  { id: 2, des: "Sentiment analysis" },
  { id: 3, des: "Notification alerts" },
  { id: 4, des: "Email newsletters" },
  { id: 5, des: "Support team assistance" },
  { id: 6, des: "Training documentation" },
  // {id:7, des:'Training documentation'}
];

export const AlphaMetricXAIText = [
  {
    id: 1,
    title: "Corporate Communications",
    image: PersonImageOne,
    description:
      "Get holistic view of your brand with custom dashboards and custom metrics to drive your corporate communications strategies. Get insights on how your communications are resonating with public and shaping your public perception.",
  },
  {
    id: 2,
    title: "PR Agencies",
    image: PersonImageTwo,
    description:
      "Quantify your PR efforts and track their performance across channels. Benchmark against competitors, measure against KPIs, and identify insights that enable data-backed decision-making and strategic recommendations.",
  },
  {
    id: 3,
    title: "Insights & Strategy",
    image: PersonImageThree,
    description:
      "Gain deeper understanding of consumer behaviors and market dynamics to plan strategic initiatives. Use real-time, actionable data to uncover macro and micro insights for customers, trends, markets, categories, products, innovations, and more.",
  },
  {
    id: 4,
    title: "Brand",
    image: PersonImageFour,
    description:
      "Get actionable insights on crucial parameters like brand perception, brand positioning, emotional response of different stakeholders, and market influence. Build stronger brand identity and increase overall market share through data-backed decisions.",
  },
  {
    id: 5,
    title: "Marketing",
    image: PersonImageFive,
    description:
      "Derive immense value from real-time insights on brand presence, public sentiment, and competitive dynamics. Identify key influencers, audience segments, and content ideas to enable targeted outreach and partnership opportunities.",
  },
  {
    id: 6,
    title: "Digital",
    image: PersonImageSix,
    description:
      "Enhance consumer journeys and optimize digital touchpoints. Use our dashboards to identify customer preferences and pain points that affect digital engagement levels and online conversions. Use data backed insights to align digital strategies to consumer expectations.",
  },
  // {id:7, description:'Training documentation'}
];

export const OurSolutionsMockData = [
  {
    id: 1,
    title: "Media Intelligence",
    description:
      "A smarter way to manage your media strategy, optimize campaigns, and demonstrate impact with precision.",
    imageURL: MediaIntelligence,
  },
  {
    id: 2,
    title: "Media Measurement",
    description:
      "Get metrics to quantify the impact of your PR strategies and benchmark outcomes against goals defined.",
    imageURL: MediaMeasurement,
  },
  {
    id: 3,
    title: "Advanced PR Metrics",
    description:
      "Get advanced analytics on audience engagement, message pull-through, and campaign performance.",
    imageURL: MediaPRMetrics,
  },
];

export const mediaTableData = [
  {
    id: 1,
    title: "Media Reporting",
    description:
      "AlphaMetricx equips analysts with real-time media tracking across traditional, digital, and social platforms. By tracking mentions, placements, and media hits, analysts can: •	Analyze where and how often a brand is being mentioned. •	Identify which outlets and journalists are covering your stories, allowing you to build stronger media relationships. •	Track trends over time to understand the impact of media coverage and its reach.",
    hoverText: "Stay on Top of Coverage",
  },
  {
    id: 2,
    title: "Customized Newsletter Reports",
    description:
      "Analysts can compile detailed reports in AlphaMetricx’s easy-to-read newsletters that can be shared with internal teams and clients. These newsletters include: •	Summary of media coverage: Highlight key articles, outlets, and overall media performance. •	Sentiment breakdowns: Showcase how the brand is being discussed and the overall tone of coverage. •	Custom insights: Tailor the report to reflect specific campaign metrics, geographic reach, or competitor analysis.",
    hoverText: "Share Insights with Stakeholders",
  },
  {
    id: 3,
    title: "Competitor Benchmarking",
    description:
      "AlphaMetricx equips analysts with real-time media tracking across traditional, digital, and social platforms. By tracking mentions, placements, and media hits, analysts can: •	Analyze where and how often a brand is being mentioned. •	Identify which outlets and journalists are covering your stories, allowing you to build stronger media relationships. •	Track trends over time to understand the impact of media coverage and its reach.",
    hoverText: "Monitoring Competitors",
  },
  {
    id: 4,
    title: "AI-Driven Sentiment Analysis",
    description:
      "AlphaMetricx equips analysts with real-time media tracking across traditional, digital, and social platforms. By tracking mentions, placements, and media hits, analysts can: •	Analyze where and how often a brand is being mentioned. •	Identify which outlets and journalists are covering your stories, allowing you to build stronger media relationships. •	Track trends over time to understand the impact of media coverage and its reach.",
    hoverText: "Understand Audience Perceptions",
  },
  {
    id: 5,
    title: "Share of Voice (SOV)",
    description:
      "AlphaMetricx equips analysts with real-time media tracking across traditional, digital, and social platforms. By tracking mentions, placements, and media hits, analysts can: •	Analyze where and how often a brand is being mentioned. •	Identify which outlets and journalists are covering your stories, allowing you to build stronger media relationships. •	Track trends over time to understand the impact of media coverage and its reach.",
    hoverText: "Benchmark Against Competitors",
  },
  {
    id: 6,
    title: "Reach and Awareness",
    description:
      "AlphaMetricx equips analysts with real-time media tracking across traditional, digital, and social platforms. By tracking mentions, placements, and media hits, analysts can: •	Analyze where and how often a brand is being mentioned. •	Identify which outlets and journalists are covering your stories, allowing you to build stronger media relationships. •	Track trends over time to understand the impact of media coverage and its reach.",
    hoverText: "Stay on Top of Coverage",
  },
  {
    id: 7,
    title: "Themes and Key Topics",
    description:
      "AlphaMetricx equips analysts with real-time media tracking across traditional, digital, and social platforms. By tracking mentions, placements, and media hits, analysts can: •	Analyze where and how often a brand is being mentioned. •	Identify which outlets and journalists are covering your stories, allowing you to build stronger media relationships. •	Track trends over time to understand the impact of media coverage and its reach.",
    hoverText: "Spot Trends",
  },
  {
    id: 8,
    title: "Journalist Analysis",
    description:
      "AlphaMetricx equips analysts with real-time media tracking across traditional, digital, and social platforms. By tracking mentions, placements, and media hits, analysts can: •	Analyze where and how often a brand is being mentioned. •	Identify which outlets and journalists are covering your stories, allowing you to build stronger media relationships. •	Track trends over time to understand the impact of media coverage and its reach.",
    hoverText: "Strengthen Media Relations",
  },
  {
    id: 9,
    title: "Campaign Planning",
    description:
      "AlphaMetricx equips analysts with real-time media tracking across traditional, digital, and social platforms. By tracking mentions, placements, and media hits, analysts can: •	Analyze where and how often a brand is being mentioned. •	Identify which outlets and journalists are covering your stories, allowing you to build stronger media relationships. •	Track trends over time to understand the impact of media coverage and its reach.",
    hoverText: "Data Driven Strategies",
  },
  // {
  //   id: 1,
  //   title: "Media Reporting",
  //   description:
  //     "AlphaMetricx equips analysts with real-time media tracking across traditional, digital, and social platforms. By tracking mentions, placements, and media hits, analysts can: •	Analyze where and how often a brand is being mentioned. •	Identify which outlets and journalists are covering your stories, allowing you to build stronger media relationships. •	Track trends over time to understand the impact of media coverage and its reach.",
  //   hoverText: "Stay on Top of Coverage",
  // },
];

export const prSolutionFlexWrapData = [
  {
    id: 1,
    title: "PR Campaign Strategists",
    image: PersonImageOne,
    description:
      "AlphaMetricx helps campaign strategists plan, execute, and optimize PR campaigns effectively. They can use AlphaMetricx for Measuring Campaign Reach and Impact, Identifying Key Influencers and Media Outlets, Analyzing, Campaign Effectiveness, Real-time Tracking of Media Coverage, Analyzing Sentiments and Key Topics.",
  },
  {
    id: 2,
    title: "Executives & Directors",
    image: PersonImageSix,
    description:
      "Communications executives can leverage AlphaMetricx for AI enabled high-level reporting, industry benchmarking, and tracking overall performance. AlphaMetricx can add a great deal of value for Monitoring and Influencing Public Relations Outcome, Visualizing PR Performance Against Key Industry Benchmarks, Identifying New Marketing Opportunities, Real-Time Awareness of Brand Perception, Aligning Message with Intended Audience.",
  },
];
