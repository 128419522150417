import React, { useRef, useState } from "react";
import Arrow from "../../assets/icons/Arrow";
import { mediaTableData } from "../../constants/mock";
import "./index.css";

const MediaTable = () => {
  const [hoverText, setHoverText] = useState(null);
  const [hoverDescp, setHoverDescp] = useState();
  const hoverRef = useRef();

  const handleOnHover = (id) => {
    setHoverText(id);
  };

  const handleOnHoverLeave = () => {
    setHoverText(null);
  };

  const handleOnDescp = (id) => {
    // To Open All thr Hover Descp
    // setHoverDescp((prev) =>
    //   prev.includes(id) ? prev.filter((someId) => someId !== id) : [...prev, id]
    // );

    setHoverDescp((prev) => (prev === id ? null : id));
  };

  return (
    <div className="media-table">
      {mediaTableData?.map((item) => (
        <div className="single-table-outer" key={item.id}>
          <div className="single-table-cell">
            <div className="table-heading-wrap">
              <div className="heading-num-wrap">
                <div className="headingId">{item?.id}</div>
                <div className="heading">{item?.title}</div>
              </div>
              <div className="hover-text-wrap" ref={hoverRef}>
                {/* {hoverText !== item?.id && !hoverDescp.includes(item?.id) && ( */}
                <div
                  className={`hover-text ${
                    // (hoverText === item?.id || hoverDescp.includes(item?.id)) &&
                    (hoverText === item?.id || hoverDescp === item?.id) &&
                    "active"
                  }`}
                  onMouseEnter={() => handleOnHover(item?.id)}
                  onMouseMove={() => handleOnHover(item?.id)}
                  onMouseLeave={handleOnHoverLeave}
                >
                  {item?.hoverText}
                </div>
                {/* )} */}
                {/* {(hoverText === item?.id || hoverDescp.includes(item?.id)) && ( */}
                <div
                  onMouseEnter={() => handleOnHover(item?.id)}
                  onMouseMove={() => handleOnHover(item?.id)}
                  onMouseLeave={handleOnHoverLeave}
                  onClick={() => handleOnDescp(item?.id)}
                  className={`more-wrap-container ${
                    hoverText === item?.id || hoverDescp === item?.id
                      ? `active ${hoverDescp === item?.id && "descriptionAct"}`
                      : ""
                  }`}
                >
                  <div className="more-wrap">
                    <div className="more-text">more</div>
                  </div>
                  <div className="more-icon-wrap">
                    <Arrow />
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
            {hoverDescp === item?.id && (
              <div className="heading-num-wrap">
                <div className="descriptionId">{hoverDescp}</div>
                <div className="description">{item?.description}</div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MediaTable;
